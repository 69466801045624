
<template>
  <div>

  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  props: {
    notification: {
      type: Object,
    },
  },
  data() {
    return {
      key: null,
      timer: null,
      interval: 0,
      progress_value: 100,
    }
  },
  methods: {
    names() {
      
    },
    ...mapActions({
      removeNotification: 'Notification/remove'
      // function: 'Module/action'
    })
  },
  mounted () {
    this.$swal({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: this.notification.timer || 4500,
      title: `${this.notification.msg}`,
      icon: this.notification.type,
    }).then(r=>{
    })
    this.timer = setTimeout(() => {
      this.removeNotification(this.notification)
    }, 5000);
    // this.decrease()
  },
  beforeDestroy(){
    clearTimeout(this.timer)
    clearInterval(this.interval)
  },
  computed: {
    name() {
      return null 
    },
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
  watch: {
    data(nv,ov) {
      if (nv){
      } else if (nv == false){
      }
    }
  },
}
</script>

<style scoped>

</style>